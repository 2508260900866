import React from 'react'
import { RoutesApp } from './components/'

function App() {

  return (
    <div className="container">
      <RoutesApp />
    </div>
  )
}

export default App